// Function component (presentational):

import React, { Suspense } from 'react'

import dayjs from 'dayjs'

import { getInitials } from '../../utils/misc'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import NavButton from '../shared/styledComponents/button/NavButton'

import renderLabel from '../shared/other/renderLabel'
import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import AvatarContainer from '../avatar/AvatarContainer'

import MeasurementTrackingContainer from '../measurement/MeasurementTrackingStatefulContainer'
import LifestyleContainer from '../lifestyle/LifestyleStatefulContainer'
import NutritionTargetsContainer from '../nutrition/NutritionTargetsContainer'
import MoodTrackingContainer from '../mood/MoodTrackingStatefulContainer'

import pattern from '../../assets/patterns/abstract-2.jpg'

import {
	UserOutlined,
	LoadingOutlined,
	UserSwitchOutlined,
	UsergroupAddOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
	UnorderedListOutlined,
	AppstoreOutlined,
	SolutionOutlined,
	ProfileOutlined,
	EditOutlined,
} from '@ant-design/icons'
import { Avatar, Button, Descriptions, Typography } from 'antd'

import { AccountTitle } from '../account/AccountsStyles'

const { Text } = Typography

const Profile = ({
	section,
	username,
	currentAccountId,
	handleSectionChange,
	togglePrivacyChange,
	isTile,
	toggleDisplay,
	account,
	isManaged,
	isPerson,
	showAccounts,
	t,
	// isLaptop,
	isDarkTheme,
}) => {
	const items = []

	const infoChildren = []

	const profileItems = [
		{
			key: 'name',
			label: t('profiles:attributes.name.label'),
			children: account?.profile?.name,
			span: 2,
		},
		{
			key: 'country',
			label: t('profiles:attributes.country.label'),
			children: account?.profile?.country?.id,
			span: 2,
		},
	]

	const renderProfileInfo = () => (
		<Card
			title={renderLabel({
				icon: <ProfileOutlined />,
				label: t('profile:sections.info.profile.title'),
			})}
		>
			<Descriptions column={2} items={profileItems} />
		</Card>
	)

	const accountable = account?.accountable

	const birthDay = accountable?.birthDate
	const birthDate = birthDay && dayjs(birthDay)
	const age = birthDay && dayjs.duration(dayjs().diff(birthDate, 'y'), 'y').humanize()

	const personalItems = [
		{
			key: 'gender',
			label: t('people:attributes.gender.label'),
			children: accountable?.gender,
			span: 2,
		},
		{
			key: 'birthDate',
			label: t('people:attributes.birthDate.label'),
			children: birthDay && `${birthDate.format('LL')} (${age})`,
			span: 2,
		},
	]

	const renderPersonalInfo = () => (
		<Card
			title={renderLabel({
				icon: <SolutionOutlined />,
				label: t('profile:sections.info.personal.title'),
			})}
		>
			<Descriptions column={2} items={personalItems} />
		</Card>
	)

	const renderBio = () => (
		<Card
			title={renderLabel({
				icon: <EditOutlined />,
				label: t('profile:sections.info.bio.title'),
			})}
		>
			<div style={{ padding: '5px 8px' }}>
				<Text type={!account?.bio && 'secondary'} italic>
					{account?.bio ?? t('profile:sections.info.bio.unavailable')}
				</Text>
			</div>
		</Card>
	)

	const isPrivate = !username

	const renderInfoChildren = () => (
		<TabPaneWrapper key="info-pane">
			{isPrivate && renderProfileInfo()}
			{isPrivate && isPerson && renderPersonalInfo()}
			{renderBio()}
		</TabPaneWrapper>
	)
	accountable && infoChildren.push(renderInfoChildren())

	const infoItem = {
		key: 'info',
		label: t('profile:sections.info.title'),
		children: infoChildren,
		disabled: !accountable,
	}
	items.push(infoItem)

	const measurementsItem = {
		key: 'measurements',
		label: t('profile:sections.measurements.title'),
		children: (
			<TabPaneWrapper key="measurements-pane">
				<Suspense fallback={renderSkeletonCard()}>
					<MeasurementTrackingContainer />
				</Suspense>
			</TabPaneWrapper>
		),
	}

	const vitalsItem = {
		key: 'vitals',
		label: t('profile:sections.vitals.title'),
		children: <TabPaneWrapper key="vitals-pane"></TabPaneWrapper>,
		disabled: true,
	}

	const lifestyleItem = {
		key: 'lifestyle',
		label: t('profile:sections.lifestyle.title'),
		children: (
			<TabPaneWrapper key="lifestyle-pane">
				<Suspense fallback={renderSkeletonCard()}>
					<LifestyleContainer />
				</Suspense>
			</TabPaneWrapper>
		),
	}

	const habitsItem = {
		key: 'habits',
		label: t('profile:sections.habits.title'),
		children: <TabPaneWrapper key="habits-pane"></TabPaneWrapper>,
		disabled: true,
	}

	const nutritionTargetsItem = {
		key: 'nutritionTargets',
		label: t('profile:sections.nutritionTargets.title'),
		children: (
			<TabPaneWrapper key="nutrition-targets-pane">
				<Suspense fallback={renderSkeletonCard()}>
					<NutritionTargetsContainer />
				</Suspense>
			</TabPaneWrapper>
		),
	}

	const favoritesItem = {
		key: 'favorites',
		label: t('profile:sections.favorites.title'),
		children: <TabPaneWrapper key="favorites-pane"></TabPaneWrapper>,
		disabled: true,
	}

	const moodsItem = {
		key: 'moods',
		label: t('profile:sections.moods.title'),
		children: (
			<TabPaneWrapper key="moods-pane">
				<Suspense fallback={renderSkeletonCard()}>
					<MoodTrackingContainer />
				</Suspense>
			</TabPaneWrapper>
		),
	}

	const selfExpressionItem = {
		key: 'selfExpression',
		label: t('profile:sections.selfExpression.title'),
		children: <TabPaneWrapper key="self-expression-pane"></TabPaneWrapper>,
		disabled: true,
	}

	isPrivate &&
		isPerson &&
		items.push(
			measurementsItem,
			vitalsItem,
			lifestyleItem,
			habitsItem,
			nutritionTargetsItem,
			favoritesItem,
			moodsItem,
			selfExpressionItem,
		)

	const publicationsChildren = [
		<TabPaneWrapper key="publications-pane">
			<Card $withMeta>
				<Card.Meta
					avatar={<AvatarContainer />}
					title={'Status update'}
					description={dayjs().format('LLLL')}
				/>
				<div style={{ marginTop: 8 }}>Something to say...</div>
			</Card>
		</TabPaneWrapper>,
	]

	const publicationsItem = {
		key: 'publications',
		label: t('profile:sections.publications.title'),
		children: publicationsChildren,
		// disabled: !accountable,
		disabled: true,
	}
	items.push(publicationsItem)

	const avatar = isPrivate ? (
		<AvatarContainer size={60} />
	) : (
		<Avatar src={account?.avatar} icon={username && <UserOutlined />} size={60}>
			{username && getInitials(username)}
		</Avatar>
	)
	const extra = !account ? (
		<LoadingOutlined />
	) : isManaged ? (
		<Button shape="circle" icon={<UserSwitchOutlined />} onClick={showAccounts} />
	) : (
		<Button
			shape="circle"
			icon={<UsergroupAddOutlined />}
			disabled
			// onClick={followAccount}
		/>
	)

	const withLeftExtra = currentAccountId === account?.id && ['', 'info', 'scans'].includes(section)

	const title = t(`profile:page.${isPrivate ? 'private' : 'public'}Title`)

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				{account && <AccountTitle>{title}</AccountTitle>}
				<Card $size={'wide'} $isAction $withBigAvatar>
					<Card.Meta
						avatar={avatar}
						title={renderLabel({
							label: account
								? isPrivate
									? account?.profile?.givenName
									: `@${account.username}`
								: title,
							extra,
						})}
					/>
				</Card>
			</PageAction>

			<Tabs
				activeKey={section}
				items={items}
				onChange={handleSectionChange}
				tabBarExtraContent={{
					left:
						withLeftExtra &&
						(['', 'info'].includes(section) ? (
							<NavButton
								icon={isPrivate ? <EyeInvisibleOutlined /> : <EyeOutlined />}
								onClick={togglePrivacyChange}
							>
								{t(`profile:page.privacy.see${isPrivate ? 'Public' : 'Private'}`)}
							</NavButton>
						) : (
							<NavButton
								icon={isTile ? <UnorderedListOutlined /> : <AppstoreOutlined />}
								onClick={toggleDisplay}
							/>
						)),
				}}
				$withLeftExtra={withLeftExtra}
			/>
		</>
	)
}

export default Profile
