import Icon, {
	CalendarOutlined,
	ScheduleOutlined,
	CheckOutlined,
	KeyOutlined,
	UserOutlined,
	SolutionOutlined,
	ColumnHeightOutlined,
	CoffeeOutlined,
	FireOutlined,
} from '@ant-design/icons'
import { ReactComponent as Scale } from '../../../assets/icons/scale.svg'
import { ReactComponent as Nutrition } from '../../../assets/icons/water-drop.svg'
import { ReactComponent as Liquid } from '../../../assets/icons/water-glass.svg'
import { ReactComponent as Solid } from '../../../assets/icons/fork-and-knife.svg'
import { ReactComponent as Mind } from '../../../assets/icons/lotus.svg'
import { ReactComponent as Sleeping } from '../../../assets/icons/sleeping-bubble.svg'
import { ReactComponent as Activity } from '../../../assets/icons/footprints.svg'

const renderLabel = ({ label, icon, extra, isWrapped = false }) => (
	<div className="block-label" style={{ display: 'flex', gap: 8 }}>
		{icon && (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-start',
				}}
			>
				<div
					style={{
						minWidth: 32,
						minHeight: 32,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{icon}
				</div>
			</div>
		)}

		<div
			style={{
				flexGrow: 1,
				lineHeight: '32px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				textWrap: isWrapped ? 'wrap' : undefined,
			}}
		>
			{label}
		</div>

		{extra && (
			<div
				style={{
					minWidth: 32,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-start',
					flexShrink: 0,
				}}
			>
				<div
					style={{
						minWidth: 32,
						minHeight: 32,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{extra}
				</div>
			</div>
		)}
	</div>
)

export const renderDateLabel = (label) => renderLabel({ icon: <CalendarOutlined />, label })
export const renderCalendarLabel = (label, extra) =>
	renderLabel({ icon: <ScheduleOutlined />, label, extra })

export const renderCheckLabel = (label, extra) =>
	renderLabel({ icon: <CheckOutlined />, label, extra })

export const renderKeyLabel = (label) => renderLabel({ icon: <KeyOutlined />, label })

export const renderUserLabel = (label) => renderLabel({ icon: <UserOutlined />, label })
export const renderNameLabel = (label) => renderLabel({ icon: <SolutionOutlined />, label })

export const renderWeightLabel = (label, extra = null) =>
	renderLabel({ icon: <Icon component={Scale} />, label, extra })
export const renderStatureLabel = (label) => renderLabel({ icon: <ColumnHeightOutlined />, label })

export const renderNutritionLabel = (label, extra = null) =>
	renderLabel({ icon: <Icon component={Nutrition} />, label, extra })
export const renderDrinkLabel = (label) => renderLabel({ icon: <Icon component={Liquid} />, label })
export const renderHotDrinkLabel = (label) => renderLabel({ icon: <CoffeeOutlined />, label })
export const renderFoodLabel = (label) => renderLabel({ icon: <Icon component={Solid} />, label })

export const renderMindLabel = (label, extra = null) =>
	renderLabel({ icon: <Icon component={Mind} />, label, extra })

export const renderFitnessLabel = (label, extra = null) =>
	renderLabel({ icon: <FireOutlined />, label, extra })
export const renderSleepingLabel = (label) =>
	renderLabel({ icon: <Icon component={Sleeping} />, label })
export const renderActivityLabel = (label) =>
	renderLabel({ icon: <Icon component={Activity} />, label })

export default renderLabel
