import { Tooltip, Table } from 'antd'

const buildColumns = ({ key, t, measure, isSI, isIndented }) => [
	{
		dataIndex: 'component',
		title: t(`${key}:columns.component.label`),
		render: (description) => {
			const splits = description.split(' | ')
			const methodology = splits[1]
			const name = splits[0]
				.replace('%', 'g/100g')
				.replace('pcm', 'mg/100g')
				.replace('pphm', 'μg/100g')
				.replace('+', ' + ')
			const amountName = name.replace('/100g', '')
			const amountNameSA = amountName.replace('g)', 'oz)')
			const isProportion = measure === 'proportion'
			let label = isProportion ? name : isSI ? amountName : amountNameSA
			if (
				[
					'Energy, without fibres (Cal/100g)',
					'Carbohydrates, available with polyols (g/100g)',
					'Carbohydrates, available (g/100g)',
					'Fibres (g/100g)',
					'Polyols (g/100g)',
					'Organic acids (g/100g)',
					'Fatty acids (g/100g)',
					'Nitrogen (g/100g)',
					'Énergie, sans fibres (Cal/100g)',
					'Glucides, disponibles avec polyols (g/100g)',
					'Glucides, disponibles (g/100g)',
					'Acides organiques (g/100g)',
					'Acides gras (g/100g)',
					'Azote (g/100g)',
					'Glucose (g/100g)',
					'Fructose (g/100g)',
					'Galactose (g/100g)',
					'Sucrose (g/100g)',
					'Lactose (g/100g)',
					'Maltose (g/100g)',
					'Saccharose (g/100g)',
					'Retinol (μg/100g)',
					'Carotene (μg/100g)',
					'β-Carotène (μg/100g)',
					'Rétinol (μg/100g)',
					'Carotène (μg/100g)',
					'β-Carotene (μg/100g)',
				].includes(name)
			) {
				label = ` - ${label}`
			}

			return <Tooltip title={methodology}>{label}</Tooltip>
		},
	},
	{
		dataIndex: 'value',
		title: t(`${key}:columns.value.label`),
		align: 'center',
	},
	{
		dataIndex: 'pctRef',
		title: t(`${key}:columns.pctRef.label`),
		render: (value) => value && `${value} %`,
		align: 'right',
	},
]

export const renderCategoryTable = ({ key, t, measure = 'amount', isSI, dataSource, category }) => (
	<Table
		columns={buildColumns({ key, t, measure, isSI })}
		dataSource={dataSource(category)}
		// showHeader={false}
		size="small"
		pagination={
			key === 'ncmComposition' && {
				pageSize: 12,
				hideOnSinglePage: true,
				size: 'small',
				position: ['none', 'bottomCenter'],
			}
		}
	/>
)
