// Function component (presentational):

import React, { Suspense } from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'

import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import GtinForm from './GtinForm'
import ScanPopularityContainer from './ScanPopularityStatefulContainer'
import ScanHistoryContainer from './ScanHistoryStatefulContainer'

import pattern from '../../assets/patterns/abstract-1.jpg'

import Icon, {
	EditOutlined,
	InfoCircleOutlined,
	UnorderedListOutlined,
	AppstoreOutlined,
} from '@ant-design/icons'
import { ReactComponent as Torch } from '../../assets/icons/torch.svg'
import { Tooltip } from 'antd'

import Reader from './ScannerStyles'

const Scanner = ({
	isReaderVisible,
	toggleReader,
	initialValues,
	navToFoodItem,
	// toggleTorch,
	isTorchAvailable,
	isAuthenticated,
	section,
	handleSectionChange,
	isTile,
	toggleDisplay,
	t,
	isDarkTheme,
}) => {
	const items = [
		{
			key: 'popularity',
			label: t('scanner:sections.popularity.title'),
			children: (
				<TabPaneWrapper key="popularity-pane" $isSplitScreen>
					<Suspense fallback={renderSkeletonCard({ withTitle: false })}>
						<ScanPopularityContainer isTile={isTile} />
					</Suspense>
				</TabPaneWrapper>
			),
		},
	]

	const historyItem = {
		key: 'history',
		label: t('scanner:sections.history.title'),
		children: (
			<TabPaneWrapper key="history-pane" $isSplitScreen>
				<Suspense fallback={renderSkeletonCard({ withTitle: false })}>
					<ScanHistoryContainer isTile={isTile} />
				</Suspense>
			</TabPaneWrapper>
		),
	}

	isAuthenticated && items.unshift(historyItem)

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				<Card
					$size={'wide'}
					style={{ height: 256, marginTop: -80, display: !isReaderVisible ? 'none' : undefined }}
				>
					<Reader id="reader" />
				</Card>
				<Card $size={'wide'} $isAction style={{ display: isReaderVisible ? 'none' : undefined }}>
					<GtinForm
						initialValues={initialValues}
						toggleReader={toggleReader}
						// options={options}
						navToFoodItem={navToFoodItem}
						t={t}
					/>
				</Card>

				{isReaderVisible && (
					<FloatMenu style={{ position: 'absolute', bottom: 96, margin: '0 8px 8px 8px' }}>
						<NavButton icon={<EditOutlined />} onClick={toggleReader}>
							{t('scanner:actions.inputGtin')}
						</NavButton>
					</FloatMenu>
				)}

				{isReaderVisible && (
					<FloatMenu style={{ position: 'absolute', bottom: 0, margin: '0 8px 8px 8px' }}>
						<NavButton
							icon={<Icon component={Torch} />}
							// onClick={toggleTorch}
							disabled={!isTorchAvailable}
						>
							{t('scanner:actions.turnOnTorch')}
						</NavButton>
					</FloatMenu>
				)}

				{isReaderVisible && !isAuthenticated && (
					<FloatMenu
						style={{ position: 'absolute', bottom: 96, right: 0, margin: '0 8px 8px 8px' }}
					>
						<Tooltip title={t('scanner:actions.info')}>
							<NavButton icon={<InfoCircleOutlined />} />
						</Tooltip>
					</FloatMenu>
				)}
			</PageAction>

			<Tabs
				activeKey={section}
				tabBarExtraContent={{
					left: (
						<NavButton
							icon={isTile ? <UnorderedListOutlined /> : <AppstoreOutlined />}
							onClick={toggleDisplay}
						/>
					),
				}}
				items={items}
				onChange={handleSectionChange}
				$withLeftExtra
			/>
		</>
	)
}

export default Scanner
