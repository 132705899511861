// Function component (stateful & container, using React, Redux, React Router, Auth0, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hooks for routing.
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
// import { useMediaQuery } from 'react-responsive'

import build from 'redux-object'

import { prefixPath } from '../../utils/l10n'
import { accountableTypes } from '../../utils/yupSchema/accountsSchema'

import { accountsShow } from '../../ducks/accounts'
import { toggleAccounts } from '../../ducks/account'

// import deviceMinWidth from '../../styles/deviceMinWidth'

import Profile from './Profile'

import { App as AntApp } from 'antd'

const ProfileStatefulContainer = () => {
	let { username } = useParams()
	const isPrivate = !username

	const apiData = useSelector((state) => state.apiData)

	const paramsAccount = username && build(apiData, 'account', username)
	const paramsAccountId = paramsAccount?.id

	const { currentAccountId, accountIds } = useSelector((state) => state.account)
	const accountId = paramsAccountId || currentAccountId
	const isManaged = accountIds.includes(accountId)

	const account = accountId && build(apiData, 'account', accountId)
	const isPerson = account?.accountableType === accountableTypes[0]
	const accountUsername = account?.username

	const { getAccessTokenSilently } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['profile', 'people', 'profiles'])

	const dispatch = useDispatch()

	const { message } = AntApp.useApp()

	const navigate = useNavigate()

	const shouldViewAccount = !account?.accountable?.journal?.id
	useEffect(() => {
		if (!shouldViewAccount) return

		const navToHome = ({ content, key = 'idError' }) => {
			message.error({ content, key })

			navigate(prefixPath('/', language), { replace: true })
		}

		const viewAccount = async (accountId) => {
			try {
				const accessToken = await getAccessTokenSilently()

				const r = await dispatch(accountsShow(language, accessToken, accountId))
				return r
			} catch (e) {
				const status = Object.values(e.errors)[0].status
				const content = Object.values(e.errors)[0].detail
				if (['404'].includes(status)) {
					navToHome({ content })
				}
				// throw e
			}
		}

		accountId && viewAccount(accountId)
	}, [
		shouldViewAccount,
		getAccessTokenSilently,
		language,
		dispatch,
		t,
		message,
		navigate,
		accountId,
	])

	let [searchParams, setSearchParams] = useSearchParams()

	const fallbackSection = 'info'
	const paramsSection = searchParams.get('section')
	const initialSection = paramsSection ?? fallbackSection

	const [section, setSection] = useState(initialSection)
	const handleSectionChange = (key) => setSection(key)

	const shouldSetSearchParams = section !== initialSection
	useEffect(() => {
		shouldSetSearchParams && setSearchParams({ section })
	}, [shouldSetSearchParams, section, setSearchParams])

	// let { pathname, search } = useLocation()
	// const currentPath = `${pathname}${search}`

	const navToProfile = (aUsername = '') => {
		const newPath = prefixPath(`${aUsername ? `account/${aUsername}` : ''}/profile`, language)

		// newPath !== currentPath && navigate(newPath)
		navigate(newPath)
	}

	const togglePrivacyChange = () => (isPrivate ? navToProfile(accountUsername) : navToProfile())

	const [isTile, setTile] = useState(true)

	const toggleTile = () => setTile((v) => !v)

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	const { isDarkTheme } = useSelector((state) => state.appearance)

	return (
		<Profile
			username={username}
			currentAccountId={currentAccountId}
			isManaged={isManaged}
			account={account}
			isPerson={isPerson}
			showAccounts={() => dispatch(toggleAccounts())}
			section={section}
			handleSectionChange={handleSectionChange}
			togglePrivacyChange={togglePrivacyChange}
			isTile={isTile}
			toggleDisplay={toggleTile}
			t={t}
			// isLaptop={isLaptop}
			isDarkTheme={isDarkTheme}
		/>
	)
}

export default ProfileStatefulContainer
