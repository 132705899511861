import styled from 'styled-components'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'

const StyledNavUserMenu = styled(FloatMenu).attrs((props) => ({
	// Defining dynamic props.
	$top: props.$top ?? 0,
	$right: props.$right ?? 0,

	// Defining static props.
	$isNav: true,
}))``

export default StyledNavUserMenu

export const ItemLabel = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
`
