import styled from 'styled-components'

import deviceMinWidth from '../../../../styles/deviceMinWidth'

import { Tabs as AntTabs } from 'antd'

const Tabs = styled(AntTabs).attrs((props) => ({
	// Defining static props.
	tabBarGutter: 8,
	indicator: { size: (origin) => origin - 16 },

	// Defining dynamic props.
	$withLeftExtra: props.$withLeftExtra ?? false,
	// $withRightExtra: props.$withRightExtra ?? false,
}))`
	// &.ant-tabs {
	// 	margin-right: 8px;
	// 	margin-left: 8px;
	// }

	padding-right: env(safe-area-inset-right);
	padding-left: env(safe-area-inset-left);

	.ant-tabs-nav {
		min-height: 48px;
		margin-bottom: 8px;
	}

	.ant-tabs-nav-list {
		margin-right: auto;
		margin-left: auto;

		.ant-tabs-tab {
			padding-right: 8px;
			padding-left: 8px;
		}

		// > .ant-tabs-tab:first-of-type {
		// 	margin-left: 16px;
		// }
		// > .ant-tabs-tab:last-of-type {
		// 	margin-right: 16px;
		// }
	}

	.ant-tabs-nav-more {
		min-width: 48px;
		margin-right: 8px;
	}

	.ant-tabs-extra-content {
		padding-left: 16px;
		padding-right: 16px;

		@media ${deviceMinWidth.mobileML} {
			margin-left: ${(props) => (props.$withLeftExtra ? 'calc((100vw - 384px) / 2)' : undefined)};
			// margin-right: ${(props) =>
				props.$withRightExtra ? 'calc((100vw - 384px) / 2)' : undefined};
		}
		@media ${deviceMinWidth.mobileXL} {
			margin-left: unset;
			// margin-right: unset;
		}
	}

	.ant-tabs-nav-wrap {
		padding-right: ${(props) => (props.$withLeftExtra ? '64px' : undefined)};
		// padding-left: ${(props) => (props.$withRightExtra ? '64px' : undefined)};

		@media ${deviceMinWidth.mobileML} {
			padding-right: ${(props) =>
				props.$withLeftExtra ? 'calc((100vw - 384px) / 2 + 64px)' : undefined};
			// padding-left: ${(props) =>
				props.$withRightExtra ? 'calc((100vw - 384px) / 2 + 64px)' : undefined};
		}
		@media ${deviceMinWidth.mobileXL} {
			padding-right: ${(props) => (props.$withLeftExtra ? '64px' : undefined)};
			// padding-left: ${(props) => (props.$withRightExtra ? '64px' : undefined)};
		}
	}
`

export default Tabs
