import Card from '../styledComponents/card/Card'

import { Skeleton } from 'antd'

const renderSkeletonCard = ({
	size = 'default',
	withTitle = true,
	withCover = false,
	withMetaAvatar = false,
} = {}) => {
	const titleProps = { width: 128 }
	const coverHeight = 'min(calc((100vw - 32px) / 2 - 16px), 160px)'

	return (
		<Card
			$size={size}
			title={withTitle && <Skeleton loading title={titleProps} paragraph={false} active />}
			cover={
				withCover && (
					<Skeleton.Image loading active style={{ height: coverHeight, width: coverHeight }} />
				)
			}
			style={{ height: '100%' }}
		>
			<Skeleton
				loading
				avatar={withMetaAvatar && { size: 32, shape: 'circle' }}
				title={titleProps}
				paragraph={false}
				active
			/>
		</Card>
	)
}

export default renderSkeletonCard
