// Function component (stateful & container, using React, Redux, React Router, i18next, React Responsive,
// & React Speech Recognition hooks):

// React State & Effect hooks.
import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hooks for routing.
import { useMatch, useLocation, useNavigate } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization (via React Context).
import { useTranslation } from 'react-i18next'
// React Responsive hook for responsive web design.
import { useMediaQuery } from 'react-responsive'
// React Speech Recognition hook.
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import { CableContext } from '../../contexts/cable/cable'

import dayjs from 'dayjs'
import _ from 'lodash'
import { Howl } from 'howler'
import build from 'redux-object'

import { avoirdupoisLocales } from '../../contexts/i18n/i18nConfig'
import { pathOptions, isLocaleWithVoice, isIntentWithImage } from '../../utils/libra'
import { signIn, signOut } from '../../utils/auth'
import { prefixPath } from '../../utils/l10n'
import { dateFormat } from '../../utils/dates'
import { scrollToId } from '../../utils/window'

import {
	speak,
	voice,
	repeat,
	goBack,
	goForward,
	randomize,
	proceed,
	silence,
	mute,
	unmute,
	listen,
	deafen,
	speakWithSound,
} from '../../ducks/libra'
import { toggleNewChat, setAnswering } from '../../ducks/assistance'
import { chatsCreate } from '../../ducks/chats'
import { toggleCalendar } from '../../ducks/journal'
import { toggleDashboard } from '../../ducks/dashboard'
import { toggleQuickEvent, setMeal, setActivity } from '../../ducks/event'
import { initSI, toggleQuickBodyMass } from '../../ducks/measurement'
import { toggleQuickMood } from '../../ducks/mood'

import deviceMinWidth from '../../styles/deviceMinWidth'

import Icon from '@ant-design/icons'
import { ReactComponent as LibraAssistant } from '../../assets/icons/libra.assistant.svg'
import { App as AntApp, Avatar } from 'antd'

import Libra from './Libra'

const LibraStatefulContainer = () => {
	const [areActionsOpen, setActionsOpen] = useState(false)
	const toggleActions = () => setActionsOpen((v) => !v)

	const [withVoiceL10n, setVoiceL10n] = useState(false)

	const [isPlaying, setPlaying] = useState(false)
	const togglePlaying = () => setPlaying((v) => !v)

	const [withSpeechRecognition, setSpeechRecognition] = useState(false)

	const {
		isSpeaking,
		intent,
		key,
		isFirst,
		speech,
		sequenceType,
		sequenceNumber,
		sequenceList,
		sequenceIndex,
		sequenceLimit,
		isEnded,
		speechCount,
		withVoice,
		voiceSrc,
		isVoiced,
		isListening,
		iframeSrc,
		iframeOpts,
		imageSrc,
		isSpeechHidden,
	} = useSelector((state) => state.libra)
	const isActuallyListening = withSpeechRecognition && isListening

	const { isNewChatOpen } = useSelector((state) => state.assistance)

	const { isDashboardOpen } = useSelector((state) => state.dashboard)

	const { isSI } = useSelector((state) => state.measurement)

	const { currentDay } = useSelector((state) => state.journal)
	const currentDate = dayjs(currentDay, dateFormat)
	const isAfterToday = dayjs(currentDate).isAfter(dayjs(), 'd')

	let { params: { navKey = '' } = {} } = useMatch('/:locale/:navKey') || {}
	const inOnboarding = navKey === 'onboarding'
	const inAssistance = navKey === 'assistance'

	const cable = useContext(CableContext)
	const connection = cable?.connection
	const isConnected = connection && !connection.disconnected

	const { loginWithRedirect, logout, isAuthenticated } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['libra', 'nav', 'measurement', 'event', 'mood', 'chats', 'assistance'])

	const hasDefaultSI = !avoirdupoisLocales.includes(language)

	const dispatch = useDispatch()

	useEffect(() => {
		isSI === '' && dispatch(initSI(hasDefaultSI))
	}, [isSI, hasDefaultSI, dispatch])

	useEffect(() => setVoiceL10n(isLocaleWithVoice(language)), [language])

	const isTablet = useMediaQuery({
		// query: '(min-width: 768px)'
		query: `${deviceMinWidth.tablet}`,
	})
	const withSideDash = isDashboardOpen && isTablet && !inOnboarding

	// const isLaptop = useMediaQuery({
	// 	// query: '(min-width: 1024px)'
	// 	query: `${deviceMinWidth.laptop}`,
	// })

	const c = (path) => t(path).split(' | ')
	const commands = [
		{
			command: c('libra:greeting.command.libra'),
			callback: () => libraAnswer('libra:greeting.speech.libra'),
		},
		{
			command: c('libra:greeting.command.hello'),
			callback: () => libraAnswer('libra:greeting.speech.hello'),
			matchInterim: true,
		},
		{
			command: c('libra:greeting.command.meeting'),
			callback: () => libraAnswer('libra:greeting.speech.meeting'),
		},
		{
			command: c('libra:greeting.command.state'),
			callback: () => libraAnswer('libra:greeting.speech.state'),
		},
		{
			command: c('libra:greeting.command.age'),
			callback: () => libraAnswer('libra:greeting.speech.age'),
		},
		{
			command: c('libra:presentation.command.who'),
			callback: () => libraAnswer('libra:presentation.speech.who'),
		},
		{
			command: c('libra:presentation.command.what'),
			callback: () => libraAnswer('libra:presentation.speech.what'),
		},
		{
			command: c('libra:presentation.command.why'),
			callback: () => libraAnswer('libra:presentation.speech.why'),
		},
		{
			command: c('libra:presentation.command.where'),
			callback: () => libraAnswer('libra:presentation.speech.where'),
		},
		{
			command: c('libra:presentation.command.when'),
			callback: () => libraAnswer('libra:presentation.speech.when'),
		},
		{
			command: c('libra:presentation.command.how'),
			callback: () => libraAnswer('libra:presentation.speech.how'),
		},
		{
			command: c('libra:presentation.command.help'),
			callback: () => libraAnswer('libra:presentation.speech.help'),
		},
		{
			command: c('libra:presentation.command.language'),
			callback: () => libraAnswer('libra:presentation.speech.language'),
		},
		{
			command: c('libra:pitch.command.elevator'),
			callback: () => libraAnswer('libra:pitch.speech.elevator'),
		},
		{
			command: c('libra:pitch.command.offering'),
			callback: () => libraAnswer('libra:pitch.speech.offering'),
		},
		{
			command: c('libra:pitch.command.audience'),
			callback: () => libraAnswer('libra:pitch.speech.audience'),
		},
		{
			command: c('libra:pitch.command.need'),
			callback: () => libraAnswer('libra:pitch.speech.need'),
		},
		{
			command: c('libra:pitch.command.secret'),
			callback: () => libraAnswer('libra:pitch.speech.secret'),
		},
		{
			command: c('libra:pitch.command.features'),
			callback: () => libraAnswer('libra:pitch.speech.features'),
		},
		{
			command: c('libra:pitch.command.founder'),
			callback: () => libraAnswer('libra:pitch.speech.founder'),
		},
		{
			command: c('libra:problem.command'),
			callback: () => libraDetail('libra:problem', 3),
		},
		{
			command: c('libra:solution.command'),
			callback: () => libraDetail('libra:solution', 3),
		},
		{
			command: c('libra:mission.command'),
			callback: () => libraDetail('libra:mission', 3),
		},
		{
			command: c('libra:explanation.command.libra360'),
			callback: () => libraAnswer('libra:explanation.speech.libra360'),
		},
		{
			command: c('libra:social.command.facebook'),
			callback: () =>
				libraAnswer('libra:social.speech.facebook', {
					// iframeSrc:
					// 	'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flibradiet&tabs=messages&width=352&height=493&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=731287787317210',
					// iframeOpts: { width: 352, height: 493 },
				}),
		},
		{
			command: c('libra:social.command.instagram'),
			callback: () => libraAnswer('libra:social.speech.instagram'),
		},
		{
			command: c('libra:social.command.youtube'),
			callback: () => libraAnswer('libra:social.speech.youtube'),
		},
		{
			command: c('libra:social.command.spotify'),
			callback: () => libraAnswer('libra:social.speech.spotify'),
		},
		{
			command: c('libra:social.command.twitter'),
			callback: () => libraAnswer('libra:social.speech.twitter'),
		},
		{
			command: c('libra:social.command.pinterest'),
			callback: () => libraAnswer('libra:social.speech.pinterest'),
		},
		{
			command: c('libra:social.command.tiktok'),
			callback: () => libraAnswer('libra:social.speech.tiktok'),
		},
		{
			command: c('libra:social.command.linkedin'),
			callback: () => libraAnswer('libra:social.speech.linkedin'),
		},
		{
			command: c('libra:music.command.workout'),
			callback: () =>
				libraAnswer('libra:music.speech.workout', {
					iframeSrc:
						'https://open.spotify.com/embed/playlist/2FWRF4AmlXvuMn7ugWvx21?utm_source=generator',
					iframeOpts: { height: 493 },
				}),
		},
		{
			command: c('libra:music.command.meditation'),
			callback: () =>
				libraAnswer('libra:music.speech.meditation', {
					iframeSrc:
						'https://open.spotify.com/embed/playlist/5HwIsyOirhCuEEUmIwmE53?utm_source=generator',
					iframeOpts: { height: 493 },
				}),
		},
		{
			command: c('libra:trivia.command'),
			callback: () => libraRandom('libra:trivia', 88),
		},
		{
			command: c('libra:story.command'),
			callback: () => libraRandom('libra:story', 25),
		},
		{
			command: c('libra:joke.command'),
			callback: () => libraRandom('libra:joke', 29),
		},
		{
			command: c('libra:surprise.command'),
			callback: () => libraSurprise(),
		},
		{
			command: c('libra:tarot.command'),
			callback: () => libraRandom('libra:tarot', 22, { sequenceLimit: 5, isSpeechHidden: true }),
		},
		{
			command: c('libra:feature.command.timer'),
			callback: (_duration) => startSpeaking('libra:feature.speech.unavailableTimer'),
		},
		{
			command: c('libra:feature.command.weather'),
			callback: () => startSpeaking('libra:feature.speech.unavailableWeather'),
		},
		{
			command: c('libra:feature.command.horoscope'),
			callback: () => startSpeaking('libra:feature.speech.unavailableHoroscope'),
		},
		{
			command: c('libra:tip.command'),
			callback: () => libraTip(),
		},
		{
			command: c('libra:response.command.yes'),
			callback: () => startSpeaking('libra:response.speech.unexpected'),
		},
		{
			command: c('libra:response.command.no'),
			callback: () => startSpeaking('libra:response.speech.unexpected'),
		},
		{
			command: c('libra:response.command.maybe'),
			callback: () => startSpeaking('libra:response.speech.unexpected'),
		},
		{
			command: c('libra:access.command'),
			callback: () => libraAccess(),
		},
		{
			command: c('libra:navigation.command.home'),
			callback: () => navTo('/'),
		},
		{
			command: c('libra:navigation.command.coaching'),
			callback: () => navTo('/coaching'),
		},
		{
			command: c('libra:navigation.command.search'),
			callback: (food) => navTo('/library', { search: food, scope: 'standardFood' }),
		},
		{
			command: c('libra:navigation.command.scanner'),
			callback: () => navTo('/scanner'),
		},
		{
			command: c('libra:navigation.command.shopping'),
			callback: () => navTo('/shopping'),
		},
		{
			command: c('libra:navigation.command.journal'),
			callback: () => navTo('/journal'),
		},
		{
			command: c('libra:navigation.command.profile'),
			callback: () => navTo('/profile'),
		},
		// {
		// 	command: c('libra:navigation.command.cooking'),
		// 	callback: () => navTo('/cooking'),
		// },
		// {
		// 	command: c('libra:navigation.command.fitness'),
		// 	callback: () => navTo('/fitness'),
		// },
		{
			command: c('libra:interface.command.calendar'),
			callback: () => checkToggleCalendar(),
		},
		{
			command: c('libra:interface.command.dashboard'),
			callback: () => checkToggleDashboard(),
		},
		{
			command: c('libra:interface.command.action'),
			callback: () => checkToggleActions(),
		},
		{
			command: c('libra:interface.command.addWater'),
			callback: () => checkToggleQuickWater(),
		},
		{
			command: c('libra:interface.command.addWeight'),
			callback: () => checkToggleQuickBodyMass(),
		},
		{
			command: c('libra:assistance.command'),
			callback: (match) => updateContent(match),
			// callback: (match) => createChat(match),
			// isFuzzyMatch: true,
			// bestMatchOnly: true,
		},
		{
			command: c('libra:prev.command'),
			callback: () => libraPrev(),
		},
		{
			command: c('libra:next.command'),
			callback: () => libraNext(),
		},
		{
			command: c('libra:repeat.command'),
			callback: () => libraRepeat(),
		},
		{
			command: c('libra:voice.command.mute'),
			callback: () => libraMute(),
		},
		{
			command: c('libra:voice.command.unmute'),
			callback: () => libraUnmute(),
		},
		{
			command: c('libra:reset.command'),
			callback: ({ resetTranscript }) => resetTranscript(),
		},
		{
			command: c('libra:cancel.command'),
			callback: () => libraStop(),
		},
		{
			command: c('libra:login.command'),
			callback: () => libraLogIn(),
		},
		{
			command: c('libra:logout.command'),
			callback: () => libraLogOut(),
		},
		{
			command: '*',
			callback: (match) => catchAll(match),
		},
	]
	const {
		isMicrophoneAvailable,
		resetTranscript,
		transcript,
		// listening
	} = useSpeechRecognition({
		commands,
	})

	const { message } = AntApp.useApp()

	const startListening = () => {
		if (!isMicrophoneAvailable)
			return message.warning({
				content: t('libra:listening.error.unavailable'),
				key: 'listeningUnavailable',
			})

		const soundSrc = require('../../assets/sounds/start-listening.wav')
		const sound = new Howl({ src: [soundSrc], volume: 0.2 })
		sound.play()

		dispatch(listen())
	}
	const stopListening = useCallback(() => {
		const soundSrc = require('../../assets/sounds/stop-listening.wav')
		const sound = new Howl({ src: [soundSrc], volume: 0.2 })
		sound.play()

		resetTranscript()
		dispatch(deafen())
	}, [resetTranscript, dispatch])
	const toggleListening = () => (isListening ? stopListening() : startListening())

	useEffect(() => {
		if (isListening) {
			if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
				message.error({
					content: t('libra:listening.error.unsupported'),
					key: 'listeningUnsupported',
				})
				stopListening()
			} else {
				!withSpeechRecognition && setSpeechRecognition(true)
			}
		}
	}, [isListening, t, message, stopListening, withSpeechRecognition])

	const startSpeaking = (path) =>
		dispatch(speak({ ...pathOptions(path), speech: t(path), withVoice: false }))
	const repeatSpeaking = useCallback(() => dispatch(repeat()), [dispatch])

	const libraStart = useCallback(
		(path, opts) => {
			const newSpeech = t(path)

			if (newSpeech === speech && !(iframeSrc || imageSrc)) return repeatSpeaking()

			dispatch(speakWithSound({ path, speech: newSpeech, ...opts, language }))
		},
		[t, speech, iframeSrc, imageSrc, repeatSpeaking, dispatch, language],
	)

	const shouldStopListening = isListening && !isMicrophoneAvailable
	useEffect(() => {
		if (!shouldStopListening) return

		libraStart('libra:listening.speech.anotherTime')
		stopListening()
	}, [shouldStopListening, libraStart, stopListening])

	const startSpeechRecognition = useCallback(
		() =>
			withSpeechRecognition &&
			SpeechRecognition.startListening({
				continuous: true,
				language,
			}),
		[withSpeechRecognition, language],
	)
	const stopSpeechRecognition = useCallback(
		() => withSpeechRecognition && SpeechRecognition.stopListening(),
		[withSpeechRecognition],
	)

	useEffect(() => {
		if (!withSpeechRecognition) return

		isListening ? startSpeechRecognition() : stopSpeechRecognition()
	}, [withSpeechRecognition, isListening, startSpeechRecognition, stopSpeechRecognition])

	const audioRef = useRef()

	const shouldPlay = withVoice && voiceSrc && !isVoiced
	useEffect(() => {
		if (!shouldPlay) return

		audioRef.current?.stop()
		// audioRef.current = new Audio(voiceSrc)
		audioRef.current = new Howl({
			src: [voiceSrc],
			format: ['mp3'],
			html5: true,
			autoplay: true,
			onload: () => dispatch(voice()),
			onplay: () => setPlaying(true),
			onpause: () => setPlaying(false),
			onstop: () => setPlaying(false),
			onend: () => setPlaying(false),
		})
	}, [shouldPlay, voiceSrc, dispatch])

	useEffect(() => {
		const { current: audio } = audioRef
		if (!audio) return

		const startPlaying = () => {
			isListening && stopSpeechRecognition()
			!audio.playing() && audio.play()
		}
		const pausePlaying = () => {
			isListening && startSpeechRecognition()
			audio.playing() && audio.pause()
		}

		isPlaying ? startPlaying() : pausePlaying()
	}, [voiceSrc, isPlaying, isListening, stopSpeechRecognition, startSpeechRecognition])

	const libraRepeat = () =>
		speech ? repeatSpeaking() : startSpeaking('libra:repeat.speech.nothing')

	const libraAnswer = useCallback(
		(path, opts) => {
			const { intent, key } = pathOptions(path)
			if (isIntentWithImage(intent)) {
				opts.imageSrc = require(`../../assets/illustrations/${intent.replace(':', '/')}/${key}.jpg`)
			}

			resetTranscript()
			libraStart(path, opts)
		},
		[resetTranscript, libraStart],
	)

	const libraDetail = (intent, end) =>
		libraAnswer(`${intent}.speech.1`, { sequenceType: 'ordered', sequenceEnd: end })

	const libraRandom = (intent, end, { zero, ...opts } = {}) => {
		const i = _.random(zero ? 0 : 1, end)

		libraAnswer(`${intent}.speech.${i}`, {
			sequenceType: 'random',
			sequenceEnd: end,
			...opts,
		})
	}

	const libraSurprise = () => {
		const i = ['trivia', 'story', 'joke']
		switch (_.sample(i)) {
			case 'trivia':
				libraRandom('libra:trivia', 88, { sequenceLimit: 1 })
				break
			case 'story':
				libraRandom('libra:story', 25, { sequenceLimit: 1 })
				break
			case 'joke':
				libraRandom('libra:joke', 29, { sequenceLimit: 1 })
				break
			default:
		}
	}

	const answerLoginNeeded = () => libraAnswer('libra:login.speech.needed')

	const libraTip = () => (isAuthenticated ? libraRandom('libra:tip', 9) : answerLoginNeeded())

	const libraAccess = () =>
		isAuthenticated
			? startSpeaking('libra:access.speech.check')
			: libraAnswer('libra:access.speech.waitlist')

	let { pathname, search } = useLocation()
	const currentPath = `${pathname}${search}`

	const navigate = useNavigate()

	const navTo = (path, searchParams = {}) => {
		if (!isAuthenticated) return answerLoginNeeded()

		const newPath = `${prefixPath(path, language)}?${new URLSearchParams(searchParams).toString()}`

		if (newPath === currentPath) {
			return startSpeaking('libra:navigation.speech.check')
		}

		libraStop()
		navigate(newPath)
	}

	const checkToggleCalendar = () => {
		if (!isAuthenticated) return answerLoginNeeded()

		libraStop()
		dispatch(toggleCalendar())
	}

	const checkToggleDashboard = () => {
		if (!isAuthenticated) return answerLoginNeeded()

		libraStop()
		dispatch(toggleDashboard())
	}

	const checkToggleActions = () => {
		if (!isAuthenticated) return answerLoginNeeded()

		if (inOnboarding) {
			return libraStart('libra:interface.speech.accountCreation')
		}

		libraStop()
		toggleActions()
	}

	const checkToggleQuickWater = () => {
		if (!isAuthenticated) return answerLoginNeeded()

		libraStop()

		dispatch(
			setMeal({ name: t('event:addMeal.waterDrinking'), foodId: 6136, physicalState: 'liquid' }),
		)
		dispatch(toggleQuickEvent())
	}

	const checkToggleQuickBodyMass = () => {
		if (!isAuthenticated) return answerLoginNeeded()

		libraStop()
		dispatch(toggleQuickBodyMass())
	}

	const { getAccessTokenSilently } = useAuth0()

	const { currentAccountId } = useSelector((state) => state.account)

	const apiData = useSelector((state) => state.apiData)

	const currentAccount = currentAccountId && build(apiData, 'account', currentAccountId)
	const assistanceId = currentAccount?.profile?.assistance?.id

	const [content, setContent] = useState('')

	const updateContent = (match) => match !== content && setContent(match)

	const catchAll = (match) => intent === 'libra:greeting' && key === 'libra' && updateContent(match)

	const shouldCreateChat = assistanceId && content
	useEffect(() => {
		if (!shouldCreateChat) return

		const navToAssistance = () => {
			const newPath = prefixPath('/assistance', language)

			currentPath !== newPath ? navigate(newPath) : scrollToId('write-chat', { behavior: 'smooth' })
		}

		const createChat = async (match) => {
			const chatAttrs = { content: match }
			const accessToken = await getAccessTokenSilently()

			const { response } = await dispatch(
				chatsCreate(language, accessToken, assistanceId, chatAttrs),
			)

			if (response) {
				message.success(t('assistance:form.message.success'))

				isConnected && dispatch(setAnswering(true))

				stopListening()
				setContent('')
				resetTranscript()
				navToAssistance()
			}
		}

		createChat(content)
	}, [
		shouldCreateChat,
		getAccessTokenSilently,
		assistanceId,
		language,
		dispatch,
		isConnected,
		content,
		t,
		message,
		stopListening,
		resetTranscript,
		currentPath,
		navigate,
	])

	const isSingleSequence = sequenceType === 'single'
	const isFirstIndex = sequenceIndex === 0
	const withPrev = !isSingleSequence && !isFirstIndex

	const libraPrev = () => {
		if (isSingleSequence) {
			return startSpeaking('libra:prev.speech.nothing')
		} else if (isFirstIndex) {
			return startSpeaking('libra:prev.speech.started')
		}

		dispatch(goBack())
	}

	const isLastIndex = sequenceIndex === sequenceList.length - 1
	const isReallyEnded = isEnded && isLastIndex
	const withNext = !isSingleSequence && !isReallyEnded

	const libraNext = () => {
		if (isSingleSequence) {
			return startSpeaking('libra:next.speech.nothing')
		} else if (isEnded) {
			!sequenceLimit
				? startSpeaking('libra:next.speech.ended')
				: startSpeaking('libra:next.speech.limited')
			return
		}

		const isRandom = sequenceType === 'random'
		!isLastIndex ? dispatch(goForward()) : isRandom ? dispatch(randomize()) : dispatch(proceed())
	}

	const shouldLibraContinue =
		!isFirst &&
		// !isSingleSequence &&
		sequenceNumber !== parseInt(key) &&
		!isReallyEnded &&
		intent !== 'food:analysis' &&
		intent !== 'recipe:instructions' &&
		intent !== 'libra:chat'
	useEffect(() => {
		shouldLibraContinue && libraAnswer(`${intent}.speech.${sequenceNumber}`, { isFirst: false })
	}, [shouldLibraContinue, intent, sequenceNumber, libraAnswer])

	const stopSpeaking = () => dispatch(silence())

	const libraStop = () => {
		isSpeaking && stopSpeaking()
		isListening && stopListening()
	}

	const libraLogIn = () => {
		if (isAuthenticated) return startSpeaking('libra:login.speech.check')

		signIn(loginWithRedirect)
	}

	const libraLogOut = () => {
		if (!isAuthenticated) return startSpeaking('libra:logout.speech.check')

		libraAnswer('libra:logout.speech.bye')
		signOut(logout)
	}

	const muteVoice = () => dispatch(mute())
	const unmuteVoice = () => dispatch(unmute())
	const toggleVoice = () => (withVoice ? muteVoice() : unmuteVoice())

	const libraMute = () => {
		resetTranscript()
		muteVoice()
	}

	const libraUnmute = () => {
		resetTranscript()
		unmuteVoice()
	}

	const renderAssistantIcon = () => <Icon component={LibraAssistant} />
	const renderAssistantAvatar = () => (
		<Avatar icon={renderAssistantIcon()} style={{ backgroundColor: 'white', color: '#06061f' }} />
	)

	const handleQuickWeightClick = () => {
		toggleActions()
		dispatch(toggleQuickBodyMass())
	}

	const handleQuickEventClick = ({ kind, name, id, physicalState }) => {
		toggleActions()

		if (kind === 'meal') {
			dispatch(setMeal({ isSimple: true, name, foodId: id, physicalState }))
		} else {
			dispatch(setActivity({ name, activityKindId: id }))
		}
		dispatch(toggleQuickEvent())
	}
	const handleQuickMealClick = (name, foodId, physicalState) =>
		handleQuickEventClick({ name, id: foodId, physicalState, kind: 'meal' })
	const handleQuickActivityClick = (name, activityKindId) =>
		handleQuickEventClick({ name, id: activityKindId, kind: 'activity' })

	const handleQuickMoodClick = () => {
		toggleActions()
		dispatch(toggleQuickMood())
	}

	const cantShowMoreActions = () => {
		toggleActions()
		answerLoginNeeded()
	}

	const handleRepeat = () => {
		toggleActions()
		libraRepeat()
	}

	const handleListening = () => {
		areActionsOpen && toggleActions()
		isPlaying && togglePlaying()

		isNewChatOpen && dispatch(toggleNewChat())
		toggleListening()
	}

	const handleNewChatClick = () => {
		if (!isAuthenticated) return answerLoginNeeded()

		areActionsOpen && toggleActions()
		libraStop()

		dispatch(toggleNewChat())
	}

	return (
		<Libra
			areActionsOpen={areActionsOpen}
			toggleActions={toggleActions}
			checkToggleActions={checkToggleActions}
			setActionsOpen={setActionsOpen}
			withVoiceL10n={withVoiceL10n}
			isPlaying={isPlaying}
			togglePlaying={togglePlaying}
			withSpeechRecognition={withSpeechRecognition}
			isSpeaking={isSpeaking}
			intentKey={key}
			intent={intent}
			speech={speech}
			iframeSrc={iframeSrc}
			imageSrc={imageSrc}
			isSpeechHidden={isSpeechHidden}
			isAfterToday={isAfterToday}
			isAuthenticated={isAuthenticated}
			withSideDash={withSideDash}
			iframeOpts={iframeOpts}
			speechCount={speechCount}
			withVoice={withVoice}
			isNewChatOpen={isNewChatOpen}
			inAssistance={inAssistance}
			isConnected={isConnected}
			isListening={isListening}
			isActuallyListening={isActuallyListening}
			resetTranscript={resetTranscript}
			transcript={transcript}
			// listening={listening}
			toggleListening={toggleListening}
			withPrev={withPrev}
			withNext={withNext}
			libraPrev={libraPrev}
			libraNext={libraNext}
			libraStop={libraStop}
			toggleVoice={toggleVoice}
			// renderAssistantIcon={renderAssistantIcon}
			renderAssistantAvatar={renderAssistantAvatar}
			handleQuickWeightClick={handleQuickWeightClick}
			handleQuickMealClick={handleQuickMealClick}
			handleQuickActivityClick={handleQuickActivityClick}
			handleQuickMoodClick={handleQuickMoodClick}
			cantShowMoreActions={cantShowMoreActions}
			handleRepeat={handleRepeat}
			handleListening={handleListening}
			handleNewChatClick={handleNewChatClick}
			t={t}
			// isLaptop={isLaptop}
		/>
	)
}

export default LibraStatefulContainer
