import styled from 'styled-components'

import deviceMinWidth from '../../../../styles/deviceMinWidth'
import { miscStyles } from '../../../../styles/misc'

const FloatMenu = styled.div.attrs((props) => ({
	// Defining dynamic props.
	$top: props.$top,
	$right: props.$right,
	$bottom: props.$bottom,
	$left: props.$left,

	$direction: props.$direction ?? 'row',

	$isCentered: props.$isCentered ?? false,
	$withSideDash: props.$withSideDash ?? false,

	$isNav: props.$isNav ?? false,
}))`
	width: auto;
	padding: 8px;

	position: fixed;
	top: ${(props) =>
		props.$top !== undefined ? `calc(${props.$top + 8}px + env(safe-area-inset-top))` : undefined};
	right: ${(props) =>
		typeof props.$right === 'number'
			? `calc(${props.$right + 8}px + env(safe-area-inset-right))`
			: typeof props.$right === 'string'
				? `calc(${props.$right} + 8px + env(safe-area-inset-right))`
				: props.$isCentered
					? '50%'
					: undefined};
	bottom: ${(props) =>
		props.$bottom !== undefined
			? `calc(${props.$bottom + 8}px + env(safe-area-inset-bottom))`
			: undefined};
	left: ${(props) =>
		typeof props.$left === 'number'
			? `calc(${props.$left + 8}px + env(safe-area-inset-left))`
			: typeof props.$left === 'string'
				? `calc(${props.$left} + 8px + env(safe-area-inset-left))`
				: undefined};
	transform: ${(props) => (props.$isCentered ? 'translateX(50%)' : undefined)};

	@media ${deviceMinWidth.mobileML} {
		right: ${(props) =>
			props.$right !== undefined
				? 'calc((100vw - 384px) / 2 + 8px + env(safe-area-inset-right))'
				: undefined};
		left: ${(props) =>
			props.$left !== undefined
				? 'calc((100vw - 384px) / 2 + 8px + env(safe-area-inset-left))'
				: undefined};
	}
	@media ${deviceMinWidth.mobileXL} {
		right: ${(props) =>
			typeof props.$right === 'number'
				? `calc(${props.$right + (props.$withSideDash ? 384 : 0) + 8}px + env(safe-area-inset-right))`
				: typeof props.$right === 'string'
					? `calc(${props.$right} + ${props.$withSideDash ? 384 : 0}px + 8px)`
					: props.$isCentered && props.$withSideDash
						? 'calc(384px + (100vw - 384px) / 2 + env(safe-area-inset-right))'
						: props.$isCentered
							? '50%'
							: undefined};
		left: ${(props) =>
			typeof props.$left === 'number'
				? `calc(${props.$left + 8}px + env(safe-area-inset-left))`
				: typeof props.$left === 'string'
					? `calc(${props.$left} - ${props.$withSideDash ? 384 : 0}px + 8px)`
					: undefined};
	}

	z-index: ${(props) => (props.$isNav ? 99 : 2)};

	display: flex;
	flex-direction: ${(props) => props.$direction};
	// align-items: center;
	gap: 16px;

	border-radius: 24px;

	background: ${(props) => props.theme.glassBackgroundColor};
	@supports not (
		(-webkit-backdrop-filter: ${miscStyles.glassBackdropFilter}) or
			(backdrop-filter: ${miscStyles.glassBackdropFilter})
	) {
		background: ${(props) => props.theme.backgroundColor};
	}

	-webkit-backdrop-filter: ${(props) => props.theme.glassBackdropFilter};
	backdrop-filter: ${(props) => props.theme.glassBackdropFilter};

	box-shadow: ${(props) => props.theme.boxShadow};
`

export default FloatMenu
