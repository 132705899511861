import styled from 'styled-components'

import { Card as AntCard } from 'antd'

const Card = styled(AntCard).attrs((props) => ({
	// Defining dynamic props.
	bordered: props.bordered ?? false,

	$size: props.$size ?? 'default',
	$isAction: props.$isAction ?? false,
	$withMeta: props.$withMeta ?? false,
	$withBigAvatar: props.$withBigAvatar ?? false,
	$withSquareAvatar: props.$withSquareAvatar ?? false,
	$withCollapse: props.$withCollapse ?? false,
	$withSingleImage: props.$withSingleImage ?? false,
}))`
	width: ${(props) =>
		props.$size === 'wide'
			? '100%'
			: props.$size === 'small'
				? 'min(calc((100vw - 32px) / 2), 176px)'
				: 'calc(100% - 16px)'};
	max-width: ${(props) => (props.$size === 'wide' ? '768px' : '368px')};

	display: ${(props) => (props.$withSingleImage ? 'flex' : undefined)};
	justify-content: ${(props) => (props.$withSingleImage ? 'center' : undefined)};

	margin-top: ${(props) => (props.$isAction ? '16px' : undefined)};

	border-radius: ${(props) => (props.$size === 'wide' ? '32px' : undefined)};
	border-top-left-radius: ${(props) => (props.$withSquareAvatar ? '8px' : undefined)};
	border-bottom-left-radius: ${(props) => (props.$withSquareAvatar ? '8px' : undefined)};

	.ant-card-cover {
		max-height: min(calc((100vw - 32px) / 2 - 16px), 160px);

		overflow: hidden;

		display: flex;
		align-items: center;

		margin: 8px 8px 0 8px;
		border-radius: 16px;
	}

	.ant-card-head {
		min-height: 48px;
	}

	.ant-card-meta-description {
		max-height: ${(props) => (props.$isAction ? '96px' : undefined)};
		overflow: ${(props) => (props.$isAction ? 'auto' : undefined)};
		margin: 6px 0;
	}

	.ant-card-body {
		padding: ${(props) => (props.$withMeta ? '6px 8px' : undefined)};
		padding: ${(props) =>
			props.$isAction && props.$withBigAvatar ? '6px 16px 6px 2px' : undefined};
		padding: ${(props) =>
			!props.$isAction && props.$withBigAvatar ? '6px 8px 6px 2px' : undefined};
		padding: ${(props) => (props.$withCollapse ? '4px 8px' : undefined)};
	}

	.ant-card-meta-avatar {
		padding-right: 8px;
	}

	.ant-card-meta-detail {
		max-height: ${(props) => (props.$isAction ? '145px' : undefined)};

		overflow: auto;

		margin: ${(props) =>
			props.$isAction && props.$withBigAvatar
				? '8px auto 4px'
				: props.$withBigAvatar
					? 'auto'
					: undefined};

		> div:not(:last-child) {
			margin-bottom: 0;
		}
	}

	.ant-card-meta-title {
		max-height: ${(props) => (props.$isAction ? '132px' : undefined)};

		overflow: auto;

		margin: 6px 0;
	}

	&.ant-card-hoverable:hover {
		transform: scale(1.03);
	}

	.ant-list-items > div:first-child:not(:last-child) {
		margin-bottom: 8px;
	}
	.ant-list-items > div:not(:first-child):not(:last-child) {
		margin-top: 8px;
		margin-bottom: 8px;
	}
	.ant-list-items > div:last-child:not(:first-child) {
		margin-top: 8px;
	}

	.ant-table-pagination.ant-pagination {
		margin: 16px 0 4px !important;
	}

	.ant-descriptions-row:first-of-type > .ant-descriptions-item {
		padding-top: 5px;
	}
	.ant-descriptions-row:last-of-type > .ant-descriptions-item {
		padding-bottom: 5px;
	}

	.ant-statistic {
		margin-left: 4px;
		margin-top: 5px;
	}
	.ant-statistic-title {
		padding-left: 1px;
	}
	.ant-statistic-content-prefix {
		margin-inline-end: 12px !important;
	}

	.ant-image-mask {
		border-radius: ${(props) => (props.$withSingleImage ? '16px' : undefined)};
	}
	.ant-image-img {
		// width: auto;

		max-width: min(352px, calc(100vw - 32px));

		max-height: ${(props) => (props.$withSingleImage ? 'calc(100vh - 144px)' : undefined)};

		border-radius: ${(props) => (props.$withSingleImage ? '16px' : undefined)};
	}

	.chart {
		padding-right: 20px;
	}

	.ant-typography {
		margin-bottom: 0;

		> p:first-child {
			margin-top: 0;
		}
		> p:last-child {
			margin-bottom: 0;
		}
	}

	.ant-skeleton-title {
		margin-block-start: 8px !important;
		margin-bottom: 8px;
	}
`

export default Card
