// Function component (container, using React, i18next & React Responsive hooks):

// React State & Effect hooks.
import React, { useState, useEffect, useCallback } from 'react'
// Redux hooks to extract data from the Redux store state & to dispatch actions.
import { useSelector, useDispatch } from 'react-redux'
// React Router hook for routing.
import { useNavigate } from 'react-router-dom'
// Auth0 hook for authentication.
import { useAuth0 } from '@auth0/auth0-react'
// i18next hook for localization.
import { useTranslation } from 'react-i18next'

import build from 'redux-object'

import { prefixPath } from '../../utils/l10n'

import { foodItemsIndex } from '../../ducks/foodItems'
import { toggleNewEvent, setMeal } from '../../ducks/event'

import L10nLink from '../shared/navigation/L10nLink'

import { BarcodeOutlined, PlusOutlined } from '@ant-design/icons'
import { Avatar, Image, Button } from 'antd'

import SearchResult from '../library/SearchResult'

import { App as AntApp } from 'antd'

const ScanPopularityStatefulContainer = ({ isTile }) => {
	const dispatch = useDispatch()

	const { getAccessTokenSilently, isAuthenticated } = useAuth0()

	const {
		i18n: { language },
		t,
	} = useTranslation(['scanner', 'auth'])

	const [pageNumber, setPageNumber] = useState(0)
	const [pageCount, setPageCount] = useState(0)

	const [loading, setLoading] = useState(false)

	const { message } = AntApp.useApp()

	let navigate = useNavigate()

	const listFoodItems = useCallback(
		async (page = 1) => {
			if (pageNumber === page) return

			if (page === 1) {
				setPageNumber(0)
				setPageCount(0)
			}
			setLoading(true)

			const accessToken = isAuthenticated ? await getAccessTokenSilently() : ''

			try {
				const { response } = await dispatch(
					foodItemsIndex(
						language,
						accessToken,
						{ isWithOrderedViews: true },
						{ size: 12, number: page },
					),
				)

				if (response) {
					const q = Object.values(response.meta)[0]

					const {
						meta: { pages },
					} = q
					setPageNumber(page)
					setPageCount(pages)
					setLoading(false)
				}
			} catch (_e) {
				message.error({ content: t('auth:login.message.error'), key: 'serverAuthError' })
				navigate(prefixPath('/', language), { replace: true })
				// throw e
			}
		},
		[pageNumber, isAuthenticated, getAccessTokenSilently, language, dispatch, t, message, navigate],
	)

	const shouldListFoodItems = pageNumber === 0
	useEffect(() => {
		shouldListFoodItems && listFoodItems()
	}, [shouldListFoodItems, listFoodItems])

	const handleFoodClick = (foodId) => {
		dispatch(setMeal({ foodId }))
		dispatch(toggleNewEvent())
	}

	const apiData = useSelector((state) => state.apiData)

	const foodItems = build(apiData, 'foodItem')
		?.filter((fi) => fi.food.viewsCount > 0)
		?.sort((a, b) => b.food.viewsCount - a.food.viewsCount)
	const dataSource = foodItems?.map(
		({
			id,
			food: { image, id: foodId, description },
			brandOwnerName,
			brandName,
			subbrandName,
		}) => ({
			type: 'foodItem',
			id,
			url: image?.url && <Image src={image.mediumUrl} preview={{ src: image.url }} />,
			avatar: (
				<Avatar
					icon={<BarcodeOutlined />}
					src={image?.url && <Image src={image.thumbUrl} preview={{ src: image.url }} />}
					size={44}
					shape="square"
				/>
			),
			title: <L10nLink to={`/library/food-item/${id}`}>{description}</L10nLink>,
			extra: isAuthenticated && (
				<Button shape="circle" icon={<PlusOutlined />} onClick={() => handleFoodClick(foodId)} />
			),
			description: [brandName, subbrandName].filter(Boolean).join(', ') || brandOwnerName,
		}),
	)

	const listMoreFoodItems = () => listFoodItems(pageNumber + 1)

	return (
		<SearchResult
			isTile={isTile}
			pageNumber={pageNumber}
			pageCount={pageCount}
			loading={loading}
			dataSource={dataSource}
			handleClick={listMoreFoodItems}
			t={t}
		/>
	)
}

export default ScanPopularityStatefulContainer
