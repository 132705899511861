// Function component (presentational):

import React, { Suspense } from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'
import NavButton from '../shared/styledComponents/button/NavButton'

import renderLabel from '../shared/other/renderLabel'
import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import List from '../shared/styledComponents/list/List'

import L10nLink from '../shared/navigation/L10nLink'

import NutritionFactsContainer from '../nutrition/NutritionFactsStatefulContainer'

import pattern from '../../assets/patterns/abstract-1.jpg'

import Icon, {
	LoadingOutlined,
	PlusOutlined,
	ThunderboltOutlined,
	LoginOutlined,
	HeartOutlined,
	ProfileOutlined,
	NodeCollapseOutlined,
	CalculatorOutlined,
	EyeOutlined,
	UnorderedListOutlined,
	WarningOutlined,
} from '@ant-design/icons'
import { ReactComponent as Nutrition } from '../../assets/icons/water-drop.svg'
import { ReactComponent as Liquid } from '../../assets/icons/water-glass.svg'
import { ReactComponent as Solid } from '../../assets/icons/fork-and-knife.svg'
import { Avatar, Image, Button, Descriptions, Statistic, Badge, Tooltip, Typography } from 'antd'

const { Link, Text } = Typography

const FoodItem = ({
	section,
	handleSectionChange,
	isAuthenticated,
	foodItem,
	isSI,
	handleAdd,
	handleAnalyze,
	handleLogin,
	// handleFavorite,
	t,
	// isLaptop,
	isDarkTheme,
}) => {
	const items = []

	const overviewChildren = []

	const brandOwnerName = foodItem?.brandOwnerName
	const food = foodItem?.food
	const branding =
		[foodItem?.brandName, foodItem?.subbrandName].filter(Boolean).join(', ') || brandOwnerName

	const infoItems = [
		// {
		// 	key: 'branding',
		// 	label: t('foodItem:sections.overview.info.branding'),
		// 	children: branding,
		// 	span: 2,
		// },
		{
			key: 'physicalState',
			label: t('foodItem:sections.overview.info.physicalState'),
			children: food?.physicalStateLabel,
			span: 2,
		},
	]

	const categoryName = foodItem?.categoryName
	const categoryItem = {
		key: 'category',
		label: t('foodItem:sections.overview.info.category'),
		children: categoryName,
		span: 2,
	}
	categoryName && infoItems.push(categoryItem)

	const ownerItem = {
		key: 'owner',
		label: t('foodItem:sections.overview.info.owner'),
		children: brandOwnerName,
		span: 2,
	}
	brandOwnerName && branding !== brandOwnerName && infoItems.push(ownerItem)

	const foodItemLink = foodItem?.url
	const linkItem = {
		key: 'link',
		label: t('foodItem:sections.overview.info.link'),
		children: (
			<Link href={foodItemLink} target="_blank">
				{t('foodItem:sections.overview.info.linkDescription')}
			</Link>
		),
		span: 2,
	}
	foodItemLink && infoItems.push(linkItem)

	const renderInfo = () => (
		<Card
			title={renderLabel({
				icon: <ProfileOutlined />,
				label: t('foodItem:sections.overview.info.title'),
			})}
			style={{ height: '100%' }}
		>
			<Descriptions column={2} items={infoItems} style={{ padding: '0 6px' }} />
		</Card>
	)

	const renderFoodLabel = ({ name, isLiquid, url, sqUrl, id }) =>
		renderLabel({
			icon: (
				<Avatar
					icon={<Icon component={!name ? Nutrition : isLiquid ? Liquid : Solid} />}
					src={url && <Image src={sqUrl} preview={{ src: url }} />}
					shape="square"
				/>
			),
			label: (
				<L10nLink to={`/library/standard-food/${id}`}>
					{name ?? t('foodItem:sections.overview.standardFood.title')}
				</L10nLink>
			),
			extra: !name ? <LoadingOutlined /> : null,
		})

	const standardFood = foodItem?.standardFood
	const stdFood = standardFood?.food
	const renderStandardFood = () => (
		<Card
			title={renderLabel({
				icon: <NodeCollapseOutlined />,
				label: t('foodItem:sections.overview.standardFood.title'),
			})}
			style={{ height: '100%' }}
		>
			{renderFoodLabel({
				id: standardFood.id,
				name: stdFood?.name,
				isLiquid: stdFood?.physicalState === 'liquid',
				url: standardFood.cartoonImage?.url ?? stdFood?.image?.url,
				sqUrl: standardFood.cartoonImage?.thumbUrl ?? stdFood?.image?.thumbUrl,
			})}
		</Card>
	)

	const measureItems = []

	const servingPortion = foodItem?.serving

	const portionType = food?.portionType
	const isCupPortion = portionType === 'cup'
	const isTbspPortion = portionType === 'tbsp'

	const cupUnit = !isSI ? '8 fl. oz.' : '240 ml'
	const cupItem = {
		key: 'cup',
		label: t('foodItem:sections.overview.measures.cup.description'),
		children: t('foodItem:sections.overview.measures.cup.value', { unit: cupUnit }),
		span: 2,
	}
	!servingPortion && isCupPortion && measureItems.push(cupItem)

	const tbspUnit = !isSI ? '0.5 fl. oz.' : '15 ml'
	const tbspItem = {
		key: 'tbsp',
		label: t('foodItem:sections.overview.measures.tbsp.description'),
		children: t('foodItem:sections.overview.measures.tbsp.value', { unit: tbspUnit }),
		span: 2,
	}
	!servingPortion && isTbspPortion && measureItems.push(tbspItem)

	const isPackSize = (fpId) => fpId === food?.foodPortion?.id
	const portions = food?.foodPortions
		?.map(({ id, name, value }) => ({
			id,
			isPackSize: isPackSize(id),
			name,
			value: +value,
		}))
		?.reverse()

	const anyPortions = portions?.length

	const buildPortionItem = ({ id, isPackSize, name: portionName }) => ({
		key: `portion-${id}`,
		label: t(`foodItem:sections.overview.measures.${isPackSize ? 'pack' : 'serving'}`),
		children: portionName,
		span: 2,
	})
	const buildPortionItems = () => anyPortions && portions.map((fp) => buildPortionItem(fp))
	anyPortions && measureItems.push(...buildPortionItems())

	const anyMeasures = measureItems.length !== 0

	const renderMeasures = () => (
		<Card
			title={renderLabel({
				icon: <CalculatorOutlined />,
				label: t('foodItem:sections.overview.measures.title'),
				extra: !portions?.map((p) => p.value)?.filter(Boolean).length ? <LoadingOutlined /> : null,
			})}
			style={{ height: '100%' }}
		>
			<Descriptions column={2} items={measureItems} style={{ padding: '0 6px' }} />
		</Card>
	)

	const renderStats = ({ title, value, prefix }) => (
		<Card $size={'small'} style={{ height: '100%' }}>
			<Statistic
				title={title}
				value={value}
				prefix={prefix}
				style={{ padding: '1px 6px 6px 2px' }}
			/>
		</Card>
	)

	const viewsCount = food?.viewsCount
	const renderViews = () =>
		renderStats({
			title: t('foodItem:sections.overview.stats.views'),
			value: viewsCount,
			prefix: <EyeOutlined />,
		})

	const nutriscoreGrade = food?.nutriscoreGrade
	const nutriscoreSrc =
		nutriscoreGrade &&
		`https://static.openfoodfacts.org/images/attributes/dist/nutriscore-${nutriscoreGrade.toLowerCase()}.svg`
	const renderNutriscore = () => (
		<Badge.Ribbon text="New">
			<Card $size={'small'}>
				<Tooltip title={t('foodItem:sections.overview.nutriscore.methodology')}>
					<Image
						alt={t('foodItem:sections.overview.nutriscore.grade', { grade: nutriscoreGrade })}
						preview={false}
						src={nutriscoreSrc}
					/>
				</Tooltip>
			</Card>
		</Badge.Ribbon>
	)

	const enoughViewsCount = viewsCount >= 12
	const renderOverviewChildren = () => (
		<TabPaneWrapper key="overview-pane">
			{renderInfo()}
			{standardFood && renderStandardFood()}
			{anyMeasures && renderMeasures()}
			{nutriscoreGrade && renderNutriscore()}
			{enoughViewsCount && renderViews()}
		</TabPaneWrapper>
	)
	foodItem && overviewChildren.push(renderOverviewChildren())

	const overviewItem = {
		key: 'overview',
		label: t('foodItem:sections.overview.title'),
		children: overviewChildren,
	}

	const ingredientsChildren = []

	const ingredientsList = foodItem?.ingredientsList

	const renderIngredientsList = () => (
		<Card
			title={renderLabel({
				icon: <UnorderedListOutlined />,
				label: t('foodItem:sections.ingredients.ingredientsList.title'),
			})}
			style={{ height: '100%' }}
		>
			<div style={{ padding: '5px 8px' }}>
				<Text>{ingredientsList}</Text>
			</div>
		</Card>
	)

	const allergens = food?.allergens
	const buildAllegenData = () =>
		allergens.map((a) => ({
			id: a.id,
			name: a.name,
			description: a.description,
			url: a.image?.url,
			sqUrl: a.image?.thumbUrl,
		}))

	const anyAllergens = allergens && Object.keys(allergens).length !== 0
	const allergensData = anyAllergens && buildAllegenData()

	const renderAllergensLabel = (item) =>
		renderLabel({
			icon: <Avatar icon={<WarningOutlined />} src={item.url && <Image src={item.url} />} />,
			label: <Tooltip title={item.description}>{item.name}</Tooltip>,
		})

	const renderAllergensList = () => (
		<Card
			title={renderLabel({
				icon: <UnorderedListOutlined />,
				label: t('foodItem:sections.ingredients.allergensList.title'),
			})}
			style={{ height: '100%' }}
		>
			<List dataSource={allergensData} renderItem={renderAllergensLabel} />
		</Card>
	)

	const renderIngredientsChildren = () => (
		<TabPaneWrapper key="ingredients-pane">
			{ingredientsList && renderIngredientsList()}
			{anyAllergens && renderAllergensList()}
		</TabPaneWrapper>
	)

	ingredientsChildren.push(renderIngredientsChildren())

	const anyIngredients = ingredientsList || anyAllergens
	const ingredientsItem = {
		key: 'ingredients',
		label: t('foodItem:sections.ingredients.title'),
		children: ingredientsChildren,
		disabled: !anyIngredients,
	}

	const renderNutritionFactsChildren = () => (
		<TabPaneWrapper key="nutrition-facts-pane">
			<Suspense fallback={renderSkeletonCard()}>
				<NutritionFactsContainer food={food} />
			</Suspense>
		</TabPaneWrapper>
	)

	const nutritionFactsItem = {
		key: 'nutritionFacts',
		label: t('foodItem:sections.nutritionFacts.title'),
		children: renderNutritionFactsChildren(),
		disabled: !food?.nutritionalComposition,
	}

	const galleryChildren = []

	const renderPhotoGallery = ({ url, medUrl }) => (
		<Card $withSingleImage>
			<Image
				src={url}
				placeholder={<Image preview={false} src={medUrl} />}
				style={{ borderRadius: 16 }}
			/>
		</Card>
	)

	const image = food?.image
	const photoUrl = image?.url
	const photoMedUrl = image?.mediumUrl

	const ingredientsImage = foodItem?.ingredientsImage
	const ingredientsUrl = ingredientsImage?.url
	const ingredientsMedUrl = ingredientsImage?.mediumUrl

	const nutritionImage = foodItem?.nutritionImage
	const nutritionUrl = nutritionImage?.url
	const nutritionMedUrl = nutritionImage?.mediumUrl

	const packagingImage = foodItem?.packagingImage
	const packagingUrl = packagingImage?.url
	const packagingMedUrl = packagingImage?.mediumUrl

	const renderGalleryChildren = () => (
		<TabPaneWrapper key="gallery-pane">
			{photoUrl && renderPhotoGallery({ url: photoUrl, medUrl: photoMedUrl })}
			{ingredientsUrl && renderPhotoGallery({ url: ingredientsUrl, medUrl: ingredientsMedUrl })}
			{nutritionUrl && renderPhotoGallery({ url: nutritionUrl, medUrl: nutritionMedUrl })}
			{packagingUrl && renderPhotoGallery({ url: packagingUrl, medUrl: packagingMedUrl })}
		</TabPaneWrapper>
	)

	galleryChildren.push(renderGalleryChildren())

	const anyImage = photoUrl

	const galleryItem = {
		key: 'gallery',
		label: t('foodItem:sections.gallery.title'),
		children: galleryChildren,
		disabled: !anyImage,
	}

	items.push(overviewItem, ingredientsItem, nutritionFactsItem, galleryItem)

	const isLiquid = food?.physicalState === 'liquid'
	const iconComponent = !foodItem ? Nutrition : isLiquid ? Liquid : Solid
	const smallUrl = image?.smallUrl
	const src = photoUrl && (
		<Image src={photoUrl} placeholder={smallUrl && <Image preview={false} src={smallUrl} />} />
	)
	const avatar = (
		<Avatar icon={<Icon component={iconComponent} />} src={src} size={60} shape="square" />
	)

	const title = food?.description ?? t('foodItem:page.title')
	const extra = !foodItem ? (
		<LoadingOutlined />
	) : isAuthenticated ? (
		<Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleAdd} />
	) : null

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				<Card $size={'wide'} $isAction $withBigAvatar $withSquareAvatar>
					<Card.Meta
						avatar={avatar}
						title={renderLabel({ label: title, extra, isWrapped: true })}
						description={branding}
					/>
				</Card>

				<FloatMenu style={{ position: 'absolute', bottom: 0, margin: '0 8px 8px 8px' }}>
					<NavButton
						icon={<ThunderboltOutlined />}
						disabled={!food}
						loading={food && !food.analysis}
						onClick={handleAnalyze}
					>
						{t('foodItem:actions.analyze')}
					</NavButton>
				</FloatMenu>

				<FloatMenu style={{ position: 'absolute', bottom: 0, right: 0, margin: '0 8px 8px 8px' }}>
					{!isAuthenticated ? (
						<NavButton icon={<LoginOutlined />} onClick={handleLogin}>
							{t('foodItem:actions.login')}
						</NavButton>
					) : (
						<NavButton
							icon={<HeartOutlined />}
							// onClick={handleFavorite}
							disabled
						>
							{t('foodItem:actions.markFavorite')}
						</NavButton>
					)}
				</FloatMenu>
			</PageAction>

			<Tabs activeKey={section} items={items} onChange={handleSectionChange} />
		</>
	)
}

export default FoodItem
