import * as yup from 'yup'

// const gtinRegex = /^\d{6}$|^\d{8}(?:\d{4,6})?$/
const gtinRegex = /^\d{8}(?:\d{4,6})?$/

export const gtinFormats = ['ean_13', 'upc_a', 'upc_e', 'ean_8']

const gtinValidation = (t) => {
	const label = t('scans:attributes.gtin.label')

	return yup
		.string()
		.matches(gtinRegex, t('scans:attributes.gtin.validation.gtinFormat'))
		.required(t('validation:mixed.required', { label }))
}

const scansSchema = (t) =>
	yup.object().shape({
		text: gtinValidation(t),
	})

export default scansSchema
