// Function component (presentational):

import React from 'react'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'
import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import { Typography } from 'antd'

const { Text } = Typography

const CardList = ({ dataSource, isTile, hoverable = true, loading }) => {
	const withData = dataSource?.length > 0

	const size = isTile ? 'small' : 'default'

	const renderCard = (item) => {
		const { description } = item
		let label = item.title
		if (item.type === 'foodItem' && !isTile && description) {
			label = (
				<>
					{label}
					<Text type="secondary" style={{ fontWeight: 'normal' }}>{` | ${description}`}</Text>
				</>
			)
		}

		return (
			<Card
				key={item.id}
				$size={size}
				hoverable={hoverable}
				cover={isTile && item.url}
				$withMeta
				$withBigAvatar={!isTile}
				$withSquareAvatar={!isTile}
				style={{ height: '100%' }}
			>
				<Card.Meta
					avatar={!isTile && item.avatar}
					title={renderLabel({ label, extra: item.extra, isWrapped: isTile })}
					description={isTile && description}
				/>
			</Card>
		)
	}

	const renderCards = () => withData && dataSource.map((item) => renderCard(item))

	return (
		<>
			{renderCards()}
			{loading &&
				renderSkeletonCard({ size, withTitle: false, withCover: isTile, withMetaAvatar: !isTile })}
		</>
	)
}

export default CardList
