import styled from 'styled-components'

import FloatMenu from '../shared/styledComponents/float/FloatMenu'

const StyledNavAppMenu = styled(FloatMenu).attrs((props) => ({
	// Defining dynamic props.
	$top: props.$top ?? 0,
	$left: props.$left ?? 0,

	// Defining static props.
	// $direction: 'column',
	$isNav: true,
}))``

export default StyledNavAppMenu
