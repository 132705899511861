import styled from 'styled-components'

const TabPaneWrapper = styled.div.attrs((props) => ({
	// Defining dynamic props.
	$isSplitScreen: props.$isSplitScreen ?? false,
}))`
	max-height: ${(props) => (props.$isSplitScreen ? 'calc(100vh - 312px)' : undefined)};
	@supports (height: 100dvh) {
		max-height: ${(props) => (props.$isSplitScreen ? 'calc(100dvh - 312px)' : undefined)};
	}

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 16px;

	overflow: ${(props) => (props.$isSplitScreen ? 'auto' : undefined)};

	padding-top: 8px;
	padding-right: 0;
	padding-bottom: ${(props) => (props.$isSplitScreen ? '72px' : '8px')};
	padding-left: 0;
`

export default TabPaneWrapper
