// Function component (presentational):

import React, { Suspense } from 'react'

import PageIllustration from '../shared/styledComponents/page/PageIllustration'
import PageAction from '../shared/styledComponents/page/PageAction'

import Card from '../shared/styledComponents/card/Card'

import renderLabel from '../shared/other/renderLabel'
import renderSkeletonCard from '../shared/other/renderSkeletonCard'

import Tabs from '../shared/styledComponents/tabs/Tabs'
import TabPaneWrapper from '../shared/styledComponents/tabs/TabPaneWrapper'

import AvatarContainer from '../avatar/AvatarContainer'

import DashboardContainer from '../dashboard/DashboardContainer'

import ObjectiveContainer from '../objective/ObjectiveStatefulContainer'
import MeasurementTrackingContainer from '../measurement/MeasurementTrackingStatefulContainer'
import NutritionTargetsContainer from '../nutrition/NutritionTargetsContainer'
import LifestyleContainer from '../lifestyle/LifestyleStatefulContainer'
import MoodTrackingContainer from '../mood/MoodTrackingStatefulContainer'

import pattern from '../../assets/patterns/abstract-2.jpg'

import Icon, { DashboardOutlined, LoadingOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { ReactComponent as Wellness } from '../../assets/icons/circle.svg'
import { Button } from 'antd'

import { AccountTitle } from '../account/AccountsStyles'

const Deck = ({
	withSideDash,
	showDashboard,
	section,
	handleSectionChange,
	account,
	isPerson,
	showAccounts,
	t,
	// isLaptop,
	isDarkTheme,
}) => {
	const items = []

	const compassPaneFallback = (
		<>
			{renderSkeletonCard()}
			{renderSkeletonCard()}
		</>
	)

	const compassItem = {
		key: 'compass',
		label: t('deck:sections.compass.title'),
		children: (
			<TabPaneWrapper key="compass-pane">
				<Suspense fallback={compassPaneFallback}>
					<ObjectiveContainer />
					<MeasurementTrackingContainer fromDeck />
				</Suspense>
			</TabPaneWrapper>
		),
	}

	const situationPaneFallback = (
		<>
			{renderSkeletonCard()}
			{renderSkeletonCard()}
			{renderSkeletonCard()}
		</>
	)

	const situationItem = {
		key: 'situation',
		label: t('deck:sections.situation.title'),
		children: (
			<TabPaneWrapper key="situation-pane">
				<Suspense fallback={situationPaneFallback}>
					<NutritionTargetsContainer fromDeck />
					<LifestyleContainer fromDeck />
					<MoodTrackingContainer fromDeck />
				</Suspense>
			</TabPaneWrapper>
		),
	}

	isPerson && items.push(compassItem, situationItem)

	const conversationsItem = {
		key: 'conversations',
		label: t('deck:sections.conversations.title'),
		children: <TabPaneWrapper key="conversations-pane"></TabPaneWrapper>,
		disabled: true,
	}
	items.push(conversationsItem)

	const avatar = <AvatarContainer size={60} />
	const extra = !account ? (
		<LoadingOutlined />
	) : (
		<Button shape="circle" icon={<UserSwitchOutlined />} onClick={showAccounts} />
	)

	const title = t('deck:page.title')

	return (
		<>
			<PageIllustration $src={pattern} $isDarkTheme={isDarkTheme} />

			<PageAction style={{ position: 'relative' }}>
				{!isPerson || !withSideDash ? (
					<>
						<div
							style={{
								width: '100%',
								position: 'absolute',
								top: 32,
								padding: '0 16px',
								zIndex: 1,
								fontSize: '16px',
								fontWeight: 600,
							}}
						>
							{renderLabel({
								icon: <Icon component={Wellness} />,
								label: t('dashboard:libra360.title'),
								// extra: (
								// 	<Button shape="circle" icon={<DashboardOutlined />} onClick={showDashboard} />
								// ),
							})}
						</div>

						<Card $size={'wide'} $isAction style={{ padding: 16 }}>
							<DashboardContainer fromDeck />
						</Card>

						<Button
							shape="circle"
							icon={<DashboardOutlined />}
							onClick={showDashboard}
							style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 1 }}
						/>
					</>
				) : (
					<>
						{account && <AccountTitle>{title}</AccountTitle>}
						<Card $size={'wide'} $isAction $withBigAvatar>
							<Card.Meta
								avatar={avatar}
								title={renderLabel({
									label: account ? account?.profile?.givenName : title,
									extra,
								})}
							/>
						</Card>
					</>
				)}
			</PageAction>

			<Tabs activeKey={section} items={items} onChange={handleSectionChange} />
		</>
	)
}

export default Deck
