// Function component (presentational):

import React from 'react'

import parse from 'html-react-parser'
import Markdown from 'react-markdown'
import rehypeExternalLinks from 'rehype-external-links'

import { replaceTags } from '../../utils/html'
import { extractYouTubeIds } from '../../utils/misc'

import { LibraBubbleBody, LibraBubbleImage, LibraBubbleText, Strong } from './LibraBubbleStyles'

import NewMoodContainer from '../mood/NewMoodContainer'

import DrawerOrModalContainer from '../shared/feedback/DrawerOrModalContainer'

import { Image, Typography } from 'antd'

const { Paragraph } = Typography

const LibraBubble = ({
	isOpen,
	hide,
	renderTitle,
	phase,
	isSpeaking,
	mask,
	withSideDash,
	iframeSrc,
	iframeOpts,
	imageSrc,
	isSpeechHidden,
	intent,
	intentKey,
	isListening,
	children,
	t,
}) => {
	const inSpeaking = phase === 'speaking'
	const inListening = phase === 'listening'
	const withTranscript = inListening && children
	const withoutTranscript = inListening && !children

	const withTitle = renderTitle && renderTitle() ? true : false

	const inMarkdown = inSpeaking && ['libra:chat', 'food:analysis'].includes(intent)

	const parsedChildren =
		children && parse(children, replaceTags([{ tagName: 'strong', component: Strong }]))

	const placeholder = t && t('chats:attributes.content.listeningPlaceholder')

	const text = withTranscript ? (
		children
	) : withoutTranscript ? (
		placeholder
	) : inMarkdown ? (
		<Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>
			{parsedChildren}
		</Markdown>
	) : (
		parsedChildren
	)

	const isCheckingMood = inSpeaking && intentKey === 'moodCheck'

	const youTubeIds = extractYouTubeIds(children)
	const youTubeSrcs = youTubeIds.map((id) => `https://www.youtube.com/embed/${id}`)

	const iframeSrcX = iframeSrc || youTubeSrcs[0]

	const withEndBlock = isCheckingMood || iframeSrcX

	const renderBody = () => (
		<LibraBubbleBody
			$phase={phase}
			$isSpeaking={isSpeaking || inSpeaking}
			$isListening={isListening}
			$withTitle={withTitle}
			$withSideDash={withSideDash}
			style={{ paddingBottom: iframeSrcX && 2 }}
		>
			{imageSrc && (
				<LibraBubbleImage
					$isListening={isListening}
					$withTitle={withTitle}
					$withSpeech={children && !isSpeechHidden}
					style={{ marginBottom: children && !isSpeechHidden ? 8 : undefined }}
				>
					<Image src={imageSrc} />
				</LibraBubbleImage>
			)}

			{text && (
				<LibraBubbleText
					$isSpeechHidden={isSpeechHidden}
					style={{ marginBottom: withEndBlock && 8 }}
				>
					<Paragraph
						className="libra-bubble-text"
						type={withoutTranscript && 'secondary'}
						italic={inListening}
					>
						{text}
					</Paragraph>
				</LibraBubbleText>
			)}

			{isCheckingMood && <NewMoodContainer fromLibra />}

			{iframeSrcX && (
				<iframe
					// id="libra-bubble-iframe"
					title="Libra Bubble iframe"
					src={iframeSrcX}
					width="100%"
					// height="100%"
					height="198"
					allowFullScreen
					allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
					loading="lazy"
					{...iframeOpts}
					seamless
					style={{ border: 0, borderRadius: 16 }}
				></iframe>
			)}
		</LibraBubbleBody>
	)

	return (
		<DrawerOrModalContainer
			id={inSpeaking ? 'libra-speaking' : 'libra-listening'}
			isOpen={isOpen}
			hide={hide}
			renderTitle={renderTitle}
			renderBody={renderBody}
			onlyModal
			mask={mask}
			fromTop={inListening && 56}
			placement={inSpeaking && 'bottom'}
			side={inSpeaking ? 'left' : 'right'}
		/>
	)
}

export default LibraBubble
